<template>
  <a
    :href="model.rootUrl"
    :class="brandCssClass"
  >
    <img
      :src="logoUrl"
      :alt="model.brand"
      :class="cssClass"
    >
  </a>
</template>

<script>
import cocioLogo from '@/assets/img/cocio_logo.svg'
import puckoLogo from '@/assets/img/pucko_logo.svg'
import cocioLogoOld from '@/assets/img/cocio_logo_old.svg'
import puckoLogoOld from '@/assets/img/pucko_logo_old.svg'

const logos = {
  cocio: cocioLogo,
  pucko: puckoLogo,
  cocio_old: cocioLogoOld,
  pucko_old: puckoLogoOld
}

export default {
  props: {
    model: {
      type: Object,
      required: true
    }
  },
  computed: {
    logoUrl() {
      const version = this.model.isOldDesign ? '_old' : ''
      return logos[`${this.model.brand.toLowerCase()}${version}`]
    },
    cssClass() {
      return [
        'c-brand-logo',
        this.model.brand.toLowerCase()
      ]
    },
    brandCssClass() {
      return this.model.brand.toLowerCase()
    }
  }
}
</script>
